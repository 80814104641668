
export const TOKEN_KEY = "TOKEN_KEY";
export const USER_KEY = "USER_KEY";

export const PERMISOS: any = {
    GESTION_USUARIOS: { permisoID: 1, denominacion: "Gestión de Usuarios" },
    GESTION_ROLES: { permisoID: 2, denominacion: "Gestión de Roles" },
    GESTION_ALUMNOS: { permisoID: 3, denominacion: "Gestión de Alumnos" },
    GESTION_PROFESORES: { permisoID: 4, denominacion: "Gestión de Profesores" },
    GESTION_CURSOS: { permisoID: 5, denominacion: "Gestión de Cursos" },
    GESTION_MOVIMIENTOS: { permisoID: 6, denominacion: "Gestión de Movimientos" },
    GESTION_MOVIMIENTOS_ANULAR: { permisoID: 7, denominacion: "Gestión de Movimientos - Anular" },
    GESTION_MOVIMIENTOS_IMPRIMIR: { permisoID: 8, denominacion: "Gestión de Movimientos - Imprimir" },
    GESTION_FACTURACION: { permisoID: 9, denominacion: "Gestión de Facturación" },
    GESTION_FACTURACION_ANULAR: { permisoID: 10, denominacion: "Gestión de Facturación - Anular" },
    GESTION_FACTURACION_GENERAR_MASIVO: { permisoID: 11, denominacion: "Gestión de Facturación - Generar Masivo" },
    GESTION_FACTURACION_PAGOS: { permisoID: 12, denominacion: "Gestión de Facturación - Pagos" },
    GESTION_FACTURACION_IMPRIMIR: { permisoID: 13, denominacion: "Gestión de Facturación - Imprimir" },
    REPORTE_RECIBOS: { permisoID: 14, denominacion: "Reporte - Recibos detallado" }
};

import { menuController } from '@ionic/core/components';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import {
  IonApp,
  IonSplitPane,
  IonMenu,
  IonContent,
  IonList,
  IonListHeader,
  IonNote,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonRouterLink,
  IonTitle,
  IonBackButton,
  IonFooter,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonToggle,
  IonAccordionGroup,
  IonAccordion, IonButton
} from '@ionic/angular/standalone';
import { AlertController } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { home, logOut, menu, layers, people, peopleCircle, personCircle, book, wallet, trendingUp, eye, checkmarkDone, cash, brush, search, pencil, arrowBack, arrowForward, add, close, trash, cashOutline, eyeOutline, chevronForward, mailOpen } from 'ionicons/icons';
import { AuthenticationService, LanguageChangerService, PermissionCheckerService } from './services';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { FormsModule } from '@angular/forms';
import { Storage } from '@ionic/storage-angular';
import { Permiso, UserLoginResponse } from './models';
import { PERMISOS } from './constants';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonButton,
    IonAccordion,
    IonAccordionGroup,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonFooter,
    IonTitle,
    RouterLink,
    RouterLinkActive,
    CommonModule,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonContent,
    IonList,
    IonListHeader,
    IonNote,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,
    IonToggle,
    IonBackButton,
    IonRouterLink,
    IonRouterOutlet,
    TranslocoPipe,
    FormsModule
  ],
  providers: [TranslocoService, LanguageChangerService, AsyncPipe]
})
export class AppComponent {

  public paletteToggle = false;
  public PERMISSIONS = PERMISOS;
  public userData: UserLoginResponse | null = null;

  constructor(
    private router: Router,
    private authenticationSrv: AuthenticationService,
    private alertcontroller: AlertController,
    private translocoService: TranslocoService,
    private langChangerSrv: LanguageChangerService,
    private permissionSrv: PermissionCheckerService,
    private storage: Storage
  ) {
    addIcons({ home, logOut, menu, layers, people, peopleCircle, book, wallet, trendingUp, eye, personCircle, checkmarkDone, cash, brush, search, pencil, arrowBack, arrowForward, add, close, trash, cashOutline, eyeOutline, chevronForward, mailOpen });

    this.storage.get("theme_mode").then((isDark) => {
      // Use matchMedia to check the user preference
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      // Initialize the dark palette based on the initial
      // value of the prefers-color-scheme media query
      this.initializeDarkPalette(isDark ?? prefersDark.matches);
      // Listen for changes to the prefers-color-scheme media query
      prefersDark.addEventListener('change', (mediaQuery) => {
        this.initializeDarkPalette(mediaQuery.matches);
      });
    });

    authenticationSrv.loginStatus$.subscribe((user) => this.userData = user);
  }

  public get activeLang(): string {
    return this.langChangerSrv.activeLang;
  }

  public logOut() {

    setTimeout(() => {
      this.alertcontroller.create({

        translucent: true,
        header: this.translocoService.translate('menu.logoutConfirmation.title'),
        message: this.translocoService.translate('menu.logoutConfirmation.message'),
        buttons: [
          {
            text: this.translocoService.translate('menu.logoutConfirmation.cancel'),
            role: 'cancel',
          },
          {
            text: this.translocoService.translate('menu.logoutConfirmation.confirm'),
            handler: async () => {
              menuController.enable(false);
              await this.authenticationSrv.LogOut();
              setTimeout(() => this.router.navigateByUrl("/login"));
            }
          },
        ],
      }).then(al => al.present());
    }, 100);
  }

  public toggleChange(ev: any): void {
    const darkMode = ev.detail.checked;
    this.toggleDarkPalette(darkMode);
    this.storage.set("theme_mode", darkMode);
  }

  public changeLang(lang: 'es' | 'en'): void {
    this.langChangerSrv.changeLanguage(lang);
  }

  public checkPermission(permissions: Permiso[]): boolean {
    const permiso = this.permissionSrv.checkPermission(permissions);
    return permiso;
  }

  private initializeDarkPalette(isDark: boolean): void {
    this.paletteToggle = isDark;
    this.toggleDarkPalette(isDark);
  }

  private toggleDarkPalette(shouldAdd: boolean): void {
    document.documentElement.classList.toggle('ion-palette-dark', shouldAdd);
  }
}

import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { catchError, Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../../services';
import { Router } from '@angular/router';

export const AuthenticationInterceptorFn: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {

  const authSrv: AuthenticationService = inject(AuthenticationService);
  const router: Router = inject(Router);
  const alertCtrl: AlertController = inject(AlertController);
  return next(req).pipe(catchError(err => {

    const error = err.error?.message || err.statusText;
    if ([401, 403].includes(err.status)) {
      authSrv.LogOut();
      router.navigateByUrl('/login');
    }
    else {
      alertCtrl.create({
        header: 'Error',
        message: err.error?.message || err.statusText,
        buttons: ['OK']
      }).then(alert => alert.present());
    }
    return throwError(() => error);
  }));
};

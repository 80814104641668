import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseHttpService } from '../base.http.service';
import { StudentAttendance } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class StudentsAttendanceService extends BaseHttpService {

  constructor() { super(); }

  public getStudentsAttendance(fecha: string, cursoID: number, cursoHorarioID:number): Observable<StudentAttendance[]> {
    return this.get<StudentAttendance[]>('CursoAlumnoPresente/GetListXCursoFecha', { fecha, cursoID,cursoHorarioID });
  }

  public saveStudentAttendance(studentAttendance: StudentAttendance[]): Observable<any> {
    return this.post<StudentAttendance[]>('CursoAlumnoPresente/PostList', studentAttendance);
  }

}

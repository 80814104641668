import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseHttpService } from '../base.http.service';
import { ReportReceipt } from 'src/app/models/reportReceipt.models';

@Injectable({
  providedIn: 'root'
})
export class ReportsReceiptService extends BaseHttpService {

  constructor() { super(); }

  public getReportsReceipt(fechaDesde: string,fechaHasta:string, cursoID: number, alumnoID:number,estadoSaldo:number): Observable<ReportReceipt[]> {
    return this.get<ReportReceipt[]>('Reporte/GetListFacturacion', { fechaDesde,fechaHasta, cursoID,alumnoID,estadoSaldo });
  }

}

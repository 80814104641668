import { Injectable } from '@angular/core';

import { Permiso } from '../../models';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class PermissionCheckerService {

  private userPermissions: Permiso[] = [];

  constructor(autenticationSrv: AuthenticationService) {
    autenticationSrv.loginStatus$.subscribe(user => {
      this.userPermissions = user?.permisos || [];
    });
  }

  public checkPermission(permissions: Permiso[]): boolean {
    let canActivate = false;

    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i];
      const hasPermission = this.userPermissions.find(p => p.permisoID === permission.permisoID);
      if (hasPermission) {
        canActivate = true;
        break;
      }
    }
    return canActivate;
  }
}

import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from "@angular/router";

import { AuthenticationService } from "../../services";

export const AuthenticationGuardFn: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> => {
    const authSrv = inject(AuthenticationService);
    const router = inject(Router);

    return !!authSrv.getLoggedUserAlias() || router.createUrlTree(['/login']);
  };

import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors, } from '@angular/common/http';
import { provideIonicAngular, } from '@ionic/angular/standalone';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { APP_INITIALIZER, importProvidersFrom, isDevMode } from '@angular/core';

import { provideTransloco } from '@jsverse/transloco';
import { AuthenticationService, TranslocoHttpLoader } from './app/services';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import {
  AuthenticationInterceptorFn,
  LoadingInterceptorFn,
} from './app/interceptors';
import { provideServiceWorker } from '@angular/service-worker';

function appInitializer(authenticationService: AuthenticationService) {
  return () => authenticationService.init();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptors([AuthenticationInterceptorFn, LoadingInterceptorFn])),
    provideIonicAngular(),
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    provideRouter(routes),
    importProvidersFrom(IonicStorageModule.forRoot({
      name: '__windsorDB',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    })),
    provideTransloco({
      config: {
        availableLangs: ['en', 'es'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }),
  ],
});
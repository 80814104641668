<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" *ngIf="userData">
      <ion-header>
        <ion-toolbar>
          <ion-buttons>
            <ion-back-button slot="start"></ion-back-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>{{ userData.apellidoNombre }}</ion-list-header>
          <ion-note>{{ userData.email }}</ion-note>

          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" detail="false" routerLinkActive="selected" routerLink="/home">
              <ion-icon slot="start" name="home"></ion-icon>
              <ion-label>{{ 'menu.home' | transloco }}</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" detail="false" routerLinkActive="selected" routerLink="/user-profile">
              <ion-icon slot="start" name="person-circle"></ion-icon>
              <ion-label>{{ 'menu.myProfile' | transloco }}</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" detail="false" routerLinkActive="selected" routerLink="/attendance">
              <ion-icon slot="start" name="checkmark-done"></ion-icon>
              <ion-label>{{ 'menu.attendance' | transloco }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
<!--
          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" detail="false" routerLinkActive="selected" routerLink="/register-payment">
              <ion-icon slot="start" name="cash"></ion-icon>
              <ion-label>{{ 'menu.payments' | transloco }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        -->
          <ion-accordion-group>
            <ion-accordion value="first">
              <ion-item slot="header">
                <ion-icon slot="start" name="layers"></ion-icon>
                <ion-label routerLink="/administration">
                  {{ 'menu.administration.administration' | transloco }}
                </ion-label>
              </ion-item>
              <ion-item slot="content" detail="false" routerLinkActive="selected" routerLink="/administration/users">
                <ion-icon slot="start" name="people"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.users' | transloco }}
                </ion-label>
              </ion-item>

              <ion-item slot="content" detail="false" routerLinkActive="selected" routerLink="/administration/students">
                <ion-icon slot="start" name="people-circle"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.students' | transloco }}
                </ion-label>
              </ion-item>

              <ion-item slot="content" detail="false" routerLinkActive="selected" routerLink="/administration/courses">
                <ion-icon slot="start" name="book"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.courses' | transloco }}
                </ion-label>
              </ion-item>

              <ion-item slot="content" detail="false" routerLinkActive="selected" routerLink="/administration/teachers">
                <ion-icon slot="start" name="people"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.teachers' | transloco }}
                </ion-label>
              </ion-item>
<!--
              <ion-item slot="content" detail="false" routerLinkActive="selected" routerLink="/administration/payments">
                <ion-icon slot="start" name="wallet"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.payments' | transloco }}
                </ion-label>
              </ion-item>-->
              <ion-item slot="content" detail="false" routerLinkActive="selected"
                routerLink="/administration/movements">
                <ion-icon slot="start" name="cash-outline"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.movements' | transloco }}
                </ion-label>
              </ion-item>
              <ion-item slot="content" detail="false" routerLinkActive="selected" routerLink="/administration/invoices">
                <ion-icon slot="start" name="cash-outline"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.invoices' | transloco }}
                </ion-label>
              </ion-item>
            <!--  <ion-item slot="content" detail="false" routerLinkActive="selected" routerLink="/administration/balance">
                <ion-icon slot="start" name="trending-up"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.balance' | transloco }}
                </ion-label>
              </ion-item>-->
              <ion-item slot="content" detail="false" routerLinkActive="selected"
                routerLink="/administration/parameters">
                <ion-icon slot="start" name="people"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.parameters' | transloco }}
                </ion-label>
              </ion-item>
              <ion-item slot="content" detail="false" routerLinkActive="selected"
                routerLink="/administration/roles">
                <ion-icon slot="start" name="eye"></ion-icon>
                <ion-label>
                  {{ 'menu.administration.systemRoles' | transloco }}
                </ion-label>
              </ion-item>

            </ion-accordion>
            <ion-accordion value="second">
              <ion-item slot="header">
                <ion-icon slot="start" name="layers"></ion-icon>
                <ion-label routerLink="/report">
                  {{ 'menu.report.report' | transloco }}
                </ion-label>
              </ion-item>
              <ion-item slot="content" detail="false" routerLinkActive="selected" routerLink="/report/report-receipt">
                <ion-icon slot="start" name="people"></ion-icon>
                <ion-label>
                  {{ 'menu.report.reportReceipt' | transloco }}
                </ion-label>
              </ion-item>

            </ion-accordion>
          </ion-accordion-group>

          <ion-menu-toggle auto-hide="false">
            <ion-item>
              <ion-icon slot="start" name="brush"></ion-icon>
              <ion-toggle [(ngModel)]="paletteToggle" (ionChange)="toggleChange($event)" justify="space-between">
                {{ 'menu.themeMode' | transloco }}
              </ion-toggle>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle auto-hide="false" (click)="logOut()">
            <ion-item lines="none" detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>
                {{ 'menu.logout' | transloco }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

        </ion-list>

        <ion-footer>
          <ion-toolbar>
            <ion-item lines="none">

              <ion-toolbar>
                <ion-buttons slot="start">
                  <ion-button color="btn-sm" fill="clear" [color]="activeLang == 'en' ? 'primary' : undefined"
                    (click)="changeLang('en')">
                    English
                  </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                  <ion-button color="small" fill="clear" [color]="activeLang == 'es' ? 'primary' : undefined"
                    (click)="changeLang('es')">
                    Español
                  </ion-button>
                </ion-buttons>
              </ion-toolbar>
            </ion-item>
          </ion-toolbar>
        </ion-footer>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>
</ion-app>
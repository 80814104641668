import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Observable } from 'rxjs';

import { Role, UserLoginResponse } from '../../models';
import { BaseHttpService } from '../base.http.service';
import { USER_KEY } from '../../constants';

@Injectable()
export class UserProfileService extends BaseHttpService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) { super(); }

  public async getUserProfile(): Promise<UserLoginResponse> {
    return await this._getStorate().then(() => this._storage?.get(USER_KEY));
  }

  public getUserbyAlias(Alias: string): Observable<UserLoginResponse> {
    return this.get<UserLoginResponse>(`Usuario/getByAlias/`, { Alias });
  }

  public getUsers(cargarRoles: boolean = true): Observable<UserLoginResponse[]> {
    return this.get<UserLoginResponse[]>('Usuario/getList', { cargarRoles });
  }

  public getRoles(): Observable<Role[]> {
    return this.get<Role[]>('Rol/getList');
  }

  public saveUserProfile(user: UserLoginResponse): Observable<{ estado: number, resultado: string, errores?: string[] }> {
    return user.usuarioID ? this.updateUser(user) : this.createUser(user);
  }

  private createUser(user: UserLoginResponse): Observable<{ estado: number, resultado: string, errores?: string[] }> {
    //return this.post<UserLoginResponse>('Usuario/Post', user);
    return this.post<{ estado: number, resultado: string, errores?: string[] }>('Usuario/Post', user);
  }

  private updateUser(user: UserLoginResponse): Observable<{ estado: number, resultado: string, errores?: string[] }> {
    return this.put<{ estado: number, resultado: string, errores?: string[] }>('Usuario/Put', user);
  }

  private _getStorate(): Promise<Storage> {
    if (this._storage === null) {
      return this.storage.create().then(s => this._storage = s);
    }
    return Promise.resolve(this._storage);
  }
}

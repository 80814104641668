import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, mergeMap, Observable, of } from 'rxjs';

import { BaseHttpService } from '../base.http.service';
import { UserLoginRequest, UserLoginResponse } from '../../models';
import { TOKEN_KEY, USER_KEY } from '../../constants';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseHttpService {

  private isInitialized = false;

  private _storage: Storage | null = null;
  private userAlias: string | null = null;

  private _loginSubject: BehaviorSubject<UserLoginResponse | null> = new BehaviorSubject<UserLoginResponse | null>(null);
  public loginStatus$: Observable<UserLoginResponse | null> = this._loginSubject.asObservable();

  constructor(private storage: Storage) {
    super();
  }

  public init(): Promise<boolean> {
    return this._getStorate()
      .then((s) => this._storage = s)
      .then(() => this._storage?.get(TOKEN_KEY))
      .then((token) => BaseHttpService.headers = { Authorization: `Bearer ${token}` })
      .then(() => this._storage?.get(USER_KEY))
      .then((user) => { this._loginSubject.next(user); return user; })
      .then((user) => this.userAlias = user?.usuarioAlias || null)
      .then(() => this.isInitialized = true);
  }

  public LogIn(loginRequest: UserLoginRequest): Observable<boolean> {

    if (!this.isInitialized) throw new Error("Storage not initialized");

    return this.post<{ token: string }>("Autenticacion/PostLogin", loginRequest)
      .pipe(mergeMap((x) => {
        const token = x?.token;

        if (token) {
          BaseHttpService.headers = { Authorization: `Bearer ${x.token}` };
          this._storage?.set(TOKEN_KEY, x.token);
          return this.getUser(loginRequest.usuario);
        }

        return of(null);
      }),
        mergeMap((user: UserLoginResponse | null) => {
          if (!user) {
            return of(false);
          }
          this.userAlias = user.usuarioAlias;
          return of(true);
        })
      );
  }

  public async LogOut(): Promise<any> {
    if (!this.isInitialized) throw new Error("Storage not initialized");

    const storage = await this._getStorate();
    await storage.remove(TOKEN_KEY);
    await storage.remove(USER_KEY);
    BaseHttpService.headers = {};
    this.userAlias = null;
    this._loginSubject.next(null);
  }

  private _getStorate(): Promise<Storage> {
    if (this._storage === null) {
      return this.storage.create().then(s => this._storage = s);
    }
    return Promise.resolve(this._storage);
  }

  public getLoggedUserAlias() {
    return this.userAlias;
  }

  public getUser(alias: string | null = null): Observable<UserLoginResponse | null> {

    return this.get<UserLoginResponse>('Usuario/getByAlias', { alias: alias || this.userAlias })
      .pipe(
        mergeMap((user: UserLoginResponse | null) => {
          if (!user) {
            return of(null);
          }
          this._storage?.set(USER_KEY, user);
          this._loginSubject.next(user);
          return of(user);
        })
      );
  }

}

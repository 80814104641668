import { Routes } from '@angular/router';
import { AuthenticationGuardFn } from './guards/authentication/authentication.guard.fn';
import { AuthorizationGuardFn } from './guards';
import { PERMISOS } from './constants';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    canActivate: [AuthenticationGuardFn]
  },
  {
    path: 'user-profile',
    loadComponent: () => import('./pages/user-profile/user-profile.page').then(m => m.UserProfilePage),
    canActivate: [AuthenticationGuardFn]
  },
  {
    path: 'recover-password',
    loadComponent: () => import('./pages/recover-password/recover-password.page').then(m => m.RecoverPasswordPage)
  },
  {
    path: 'attendance',
    loadComponent: () => import('./pages/attendance/attendance.page').then(m => m.AttendancePage),
    canActivate: [AuthorizationGuardFn],
    data: {
      permissions: [
        PERMISOS.REGISTRO_ASISTENCIA_ALUMNOS
      ]
    }    
  },
  {
    path: 'register-payment',
    loadComponent: () => import('./pages/register-payment/register-payment.page').then(m => m.RegisterPaymentPage),
    canActivate: [AuthenticationGuardFn]
  },
  {
    path: 'administration',
    canActivate: [AuthenticationGuardFn],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/administration/administration.page').then(m => m.AdministrationPage),
      },
      {
        path: 'users',
        loadComponent: () => import('./pages/administration/users/users.page').then(m => m.UsersPage),
         canActivate: [AuthorizationGuardFn],
         data: {
           permissions: [
             PERMISOS.GESTION_USUARIOS
           ]
         }
      },
      {
        path: 'roles',
        loadComponent: () => import('./pages/administration/roles/roles.page').then(m => m.RolesPage),
        canActivate: [AuthorizationGuardFn],
        data: {
          permissions: [
            PERMISOS.GESTION_ROLES
          ]
        }
      },
      {
        path: 'students',
        loadComponent: () => import('./pages/administration/students/students.page').then(m => m.StudentsPage),
         canActivate: [AuthorizationGuardFn],
         data: {
           permissions: [
             PERMISOS.GESTION_ALUMNOS
           ]
         }
      },
      {
        path: 'payments',
        loadComponent: () => import('./pages/administration/payments/payments.page').then(m => m.PaymentsPage),
        //canActivate: [AuthorizationGuardFn],
        // data: {
        //   permissions: [
        //     PERMISOS.GESTION_FACTURACION_PAGOS,
        //     PERMISOS.GESTION_FACTURACION,
        //     PERMISOS.GESTION_FACTURACION_ANULAR,
        //     PERMISOS.GESTION_FACTURACION_IMPRIMIR
        //   ]
        // }
      },
      {
        path: 'movements',
        loadComponent: () => import('./pages/administration/movements/movements.page').then(m => m.MovementsPage),
         canActivate: [AuthorizationGuardFn],
         data: {
           permissions: [
             PERMISOS.GESTION_MOVIMIENTOS,
             PERMISOS.GESTION_MOVIMIENTOS_ANULAR,
             PERMISOS.GESTION_MOVIMIENTOS_IMPRIMIR
           ]
         }
      },
      {
        path: 'invoices',
        loadComponent: () => import('./pages/administration/invoices/invoices.page').then(m => m.InvoicesPage),
         canActivate: [AuthorizationGuardFn],
         data: {
           permissions: [
             PERMISOS.GESTION_FACTURACION,
             PERMISOS.GESTION_FACTURACION_ANULAR,
             PERMISOS.GESTION_FACTURACION_IMPRIMIR
           ]
        }
      },
      {
        path: 'courses',
        loadComponent: () => import('./pages/administration/courses/courses.page').then(m => m.CoursesPage),
        canActivate: [AuthorizationGuardFn],
         data: {
           permissions: [
             PERMISOS.GESTION_CURSOS,
           ]
         }
      },
      {
        path: 'teachers',
        loadComponent: () => import('./pages/administration/teachers/teachers.page').then(m => m.TeachersPage),
        canActivate: [AuthorizationGuardFn],
         data: {
           permissions: [
             PERMISOS.GESTION_PROFESORES
           ]
         }
      },
      {
        path: 'balance',
        loadComponent: () => import('./pages/administration/balance/balance.page').then(m => m.BalancePage),
        //canActivate: [AuthorizationGuardFn],
        // data: {
        //   permissions: [
        //     PERMISOS.GESTION_FACTURACION,

        //   ]
        // }
      },
      {
        path: 'parameters',
        loadComponent: () => import('./pages/administration/parameters/parameters.page').then(m => m.ParametersPage),
        canActivate: [AuthorizationGuardFn],
         data: {
           permissions: [
             PERMISOS.GESTION_PARAMETROS
           ]
         }        
      },
      
    ]
  },
  {
    path: 'report',
    canActivate: [AuthenticationGuardFn],
    children: [
      {
        path: 'report-receipt',
        loadComponent: () => import('./pages/report/report-receipt/report-receipt.page').then(m => m.ReportReceiptPage),
        canActivate: [AuthorizationGuardFn],
         data: {
           permissions: [
             PERMISOS.REPORTE_RECIBOS
           ]
         }
      },

    ]
  }
];

import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';

import { catchError, finalize, from, merge, mergeMap, Observable } from 'rxjs';

export const LoadingInterceptorFn: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {

  const loadingCtrl: LoadingController = inject(LoadingController);

  const loadObs$ = from(
    loadingCtrl.create({
      animated: true,
      backdropDismiss: false,
      keyboardClose: false,
      showBackdrop: true,
      spinner: 'crescent',
    })
  );

  let createdLoader: HTMLIonLoadingElement;

  return loadObs$.pipe(
    mergeMap((loading) => {
      createdLoader = loading;
      createdLoader.present();
      return next(req);
    }),
    finalize(() => createdLoader.dismiss())
  );
};

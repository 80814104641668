import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";

import { Observable } from "rxjs";

import { environment } from "../../environments/environment.prod";

export abstract class BaseHttpService {
    protected http: HttpClient = inject(HttpClient);

    protected static headers = {};

    protected get<T>(url: string, params: any = undefined): Observable<T> {
        return this.http.get<T>(`${environment.apiUrl}/${url}`, { headers: BaseHttpService?.headers, params });
    }

    protected post<T>(url: string, body: any = undefined): Observable<T> {
        return this.http.post<T>(`${environment.apiUrl}/${url}`, body, { headers: BaseHttpService?.headers });
    }

    protected put<T>(url: string, body: any = undefined): Observable<T> {
        return this.http.put<T>(`${environment.apiUrl}/${url}`, body, { headers: BaseHttpService?.headers });
    }

    protected delete<T>(url: string, params: any = undefined): Observable<T> {
        return this.http.delete<T>(`${environment.apiUrl}/${url}`, { headers: BaseHttpService?.headers, params });
    }

    protected patch<T>(url: string, body: any = undefined): Observable<T> {
        return this.http.patch<T>(`${environment.apiUrl}/${url}`, body, { headers: BaseHttpService?.headers });
    }
}
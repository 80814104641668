import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, GuardResult, MaybeAsync, RouterStateSnapshot } from "@angular/router";
import { PermissionCheckerService } from "../../services";
import { UrlTree, Router } from "@angular/router";

export const AuthorizationGuardFn: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> => {
    const router = inject(Router);
    const permissionCheckerService = inject(PermissionCheckerService);
    const permissions = route.data["permissions"];
    return permissionCheckerService.checkPermission(permissions) || router.parseUrl('/');
  };

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { TranslocoService } from '@jsverse/transloco';

const USER_LANG = "SELECTED_LANG"

@Injectable({ providedIn: "root" })
export class LanguageChangerService {

  public activeLang: 'es' | 'en' = this.translocoSrv.config.defaultLang as any;

  constructor(private translocoSrv: TranslocoService, storage: Storage) {
    setTimeout(() => {
      storage.get(USER_LANG)
        .then((lang: 'es' | 'en' | null) => {
          translocoSrv.setActiveLang(lang || translocoSrv.config.defaultLang);
        });
      this.translocoSrv.langChanges$.subscribe(l => {
        storage.set(USER_LANG, l);
        this.activeLang = l as any;
      });
    }, 10);
  }

  public async changeLanguage(lang: 'es' | 'en'): Promise<void> {
    this.translocoSrv.setActiveLang(lang);
  }
}
